<template>
    <div :class="['result',Number($route.query.type)>2?'juesai':'fusai']">
        <div class="result-main">
            <div class="result-title">
                <h2>泌尿青年医师手术&病例大会</h2>
                <!--        <i>(第{{num}}组)</i>-->
                <i>{{ title }}</i>
                <h3>优胜战队</h3>
            </div>
            <div class="result-head">
                <div class="result-carry" v-for="(item,index) in list">
                    <div class="carry-head">
                        <img :src="item.battles_img3"/>
						<!-- <img :src="item.battles_img3 || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'"/> -->
                    </div>
                    <div class="carry-name">{{ item.battles_name }}<p>{{ item.hospital_name }}</p></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '',
                list: []
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
				this.list=[]
                this.$axios.post(this.apiUrl.baiji23resultBattles,{group_id:this.$route.query.group_id}).then(res => {
					let type=Number(this.$route.query.type)
					if(type==1||type==3){
						this.list = res.data.slice(0,2)
					}else if(type==2||type==4){
						this.list = res.data.slice(0,1)
					}
                    this.title = res.msg
                })
            },
        }
    }
</script>
<style scoped lang="scss">
	.juesai{
		background: url("~@/assets/img/baiji/bj2024-2.jpg") no-repeat;
		// background: url("~@/assets/img/baiji/bj2023-2.jpg") no-repeat;
	}
	.fusai{
		background: url("~@/assets/img/baiji/bj2024-2.jpg") no-repeat;
		// background: url("~@/assets/img/baiji/bj2023-2-1.jpg") no-repeat;
	}
    .result{
        
        background-size: 100% 100%;
        height: 100vh;
        .result-main{/*
            background: rgba(000,000,000,.3);*/
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            .result-title{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-weight: bold;
                font-family:SimHei;
                color: #bf3763;
                margin-top: 40px;
                h2{
                    font-size: 35px;
                }
                i{font-size: 35px;margin: 10px 0}
                h3{
                    font-size: 50px;
                    text-align: center;
                    font-weight: bold;
                    font-family: PangMenZhengDao;
                    text-transform:uppercase;
                    color:#bf3763;
                    text-shadow:
                            0px 1px 0px #c0c0c0,
                            0px 2px 0px #C4C4C4,
                            0px 3px 0px #CACACA,
                            0px 4px 0px #CFCFCF,
                            0px 3px 5px rgba(255, 255, 255, 0.7);
                    letter-spacing:3px;

                }
            }
            .result-head{
                margin-top: -30px;
                .result-carry{
                    vertical-align: top;
                    // margin: 0 5%;
                }
            }
            .result-carry{
                display: inline-block;
                box-sizing: border-box;
                position: relative;
                .carry-ranking{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 50px;
                    height: 50px;
                }
                .carry-head{
                    // width: 200px;
                    // height: 200px;
                    // background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/tximg.png") no-repeat;
                    background-size: 100% 100%;
                    // padding: 25px;
                    margin: 0 auto;
                    img{
						width: 900px;
                        // width: 100%;
                        // height: 100%;
                        // border-radius: 100%;
                    }
                }
                .carry-name{
                    text-align: center;
                    font-size: 28px;
                    font-weight:bold;
                    margin-bottom: 30px;
                    color: #bf3763;
                    margin-top: -40px;
                }
            }
        }
    }
</style>

